import React from 'react'
import { Link } from 'gatsby'

const Calltoaction = () => (
  <section className="contact-port">
    <div className="row container">
      <div className="col s12 m12 l10 call-to-action">
        <h3 className="white-text">Have a project in mind?</h3>
        <p className="white-text" style={{ fontSize: '18px' }}>
          Contact me to discuss the project and make it a reality!
        </p>
      </div>
      <div className="col s12 m12 l2 call-to-action">
        <Link
          to="/contact"
          className="waves-effect waves-light btn-large home-btn"
          style={{ marginTop: '45px' }}
        >
          <i className="material-icons right">arrow_forward</i>Contact Me
        </Link>
      </div>
    </div>
  </section>
)

export default Calltoaction
