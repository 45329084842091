import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../utils/PageHeader'
import Calltoaction from '../components/calltoaction'

const Portfolio = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressWpPortfolio.title}
      keywords={['']}
      description={data.wordpressWpPortfolio.yoast_meta.yoast_wpseo_metadesc}
    />
    <PageHeader
      img={
        data.wordpressWpPortfolio.acf.poster_url.localFile !== null
          ? data.wordpressWpPortfolio.acf.poster_url.localFile.childImageSharp
            .fluid
          : ''
      }
      styleClass="content-bg"
    >
      <div className="container">
        <h1 className="white-text portfolio-heading">
          {data.wordpressWpPortfolio.title}
        </h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col s12 m12 l4">
            {data.wordpressWpPortfolio.featured_media ? (
              <Img
                fluid={
                  data.wordpressWpPortfolio.featured_media.localFile
                    .childImageSharp.fluid
                }
              />
            ) : null}
            {/* <h6>
              Category:{' '}
              {data.wordpressWpPortfolio.portfolio_category.map(({ name }) => (
                <span className="port-cat">{name} </span>
              ))}
            </h6> */}
            <h6>
              URL:{' '}
              <a
                href={data.wordpressWpPortfolio.acf.project_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.wordpressWpPortfolio.title}
              </a>
            </h6>

            <Link
              to="/portfolios"
              className="waves-effect waves-light btn-small home-btn"
              style={{ marginTop: '35px' }}
            >
              <i className="material-icons left">arrow_back</i>Return to
              Portfolios
            </Link>
          </div>
          <div className="col s12 m12 l8">
            <div
              className="portfolio-text"
              dangerouslySetInnerHTML={{
                __html: data.wordpressWpPortfolio.content,
              }}
            />
          </div>
        </div>
      </div>

      {/*Contact*/}
      <Calltoaction />
    </PageHeader>
  </Layout>
)

export default Portfolio
export const query = graphql`
  query PortfolioQuery($link: String!) {
    wordpressWpPortfolio(link: { eq: $link }) {
      title
      link
      content
      portfolio_category
      acf {
        project_url
        poster_url {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1902) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
